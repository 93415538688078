var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Navbar', {
    attrs: {
      "nav-light": true
    }
  }), _c('section', {
    staticClass: "bg-half bg-primary d-table w-100"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('div', {
    staticClass: "page-next-level"
  }, [_c('h4', {
    staticClass: "title text-white"
  }, [_vm._v(_vm._s(_vm.$t("hibah-amendment")))])])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "d-flex flex-row-reverse"
  }, [[null, 5].includes(_vm.amendment_index_status) ? _c('b-button', {
    staticClass: "btn-sm btn-primary m-1",
    attrs: {
      "disabled": _vm.btnMohon
    },
    on: {
      "click": _vm.applyAmendment
    }
  }, [_vm._v(_vm._s(_vm.$t("amendment.apply-new")))]) : _vm._e(), _vm.btnPrint ? _c('b-button', {
    staticClass: "btn-sm btn-success m-1",
    on: {
      "click": _vm.printHibah
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("download-hibah-cert")) + " ")]) : _vm._e()], 1), _c('div', {
    staticClass: "row mt-3"
  }, [_c('table', {
    staticClass: "table table-hover"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(_vm._s(_vm.$t("index")))]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(_vm._s(_vm.$t("amendment.date-of-amendment")))]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(_vm._s(_vm.$t("amendment.of")))]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("Status")]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(_vm._s(_vm.$t("action")))])])]), _c('tbody', [_vm._l(this.amendment_array, function (item, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(_vm._s(_vm._f("getConvertDate")(item.created_at)))]), _c('td', [_vm._v(_vm._s(item.count))]), _c('td', [_vm._v(" " + _vm._s(_vm._f("amendmentStatus")(item.status)) + " ")]), _c('td', [item.status == 0 ? _c('b-button', {
      staticClass: "btn-sm btn-danger",
      attrs: {
        "block": ""
      },
      on: {
        "click": _vm.openPindaan
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("update")))]) : _vm._e(), item.status == 2 ? _c('b-button', {
      staticClass: "btn-sm btn-success",
      on: {
        "click": function ($event) {
          return _vm.VerifiedHibah(item, index);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("hibah-draft-confirmation")) + " ")]) : _vm._e(), item.status == 3 ? _c('b-button', {
      staticClass: "btn-sm btn-success",
      on: {
        "click": function ($event) {
          return _vm.VerifiedPenerima(item, index);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("recipient-verification")) + " ")]) : _vm._e(), item.status == 4 ? _c('b-button', {
      staticClass: "btn-sm btn-success",
      on: {
        "click": function ($event) {
          return _vm.VerifiedSaksi(item, index);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("witness-confirmation")) + " ")]) : _vm._e(), item.status == 5 ? _c('a', [_vm._v(" " + _vm._s(_vm.$t("print-hibah-cert")) + " ")]) : _vm._e()], 1)]);
  }), _vm.amendment_array.length == 0 ? _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "5"
    }
  }, [_c('h5', {
    staticClass: "mt-5 mb-5"
  }, [_vm._v(_vm._s(_vm.$t("amendment.no-records")))])])]) : _vm._e()], 2)])])])]), _c('Footer'), _c('a', {
    directives: [{
      name: "scroll-to",
      rawName: "v-scroll-to",
      value: '#topnav',
      expression: "'#topnav'"
    }],
    staticClass: "btn btn-icon btn-soft-primary back-to-top",
    attrs: {
      "href": "javascript: void(0);",
      "id": "back-to-top"
    }
  }, [_c('arrow-up-icon', {
    staticClass: "icons"
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }